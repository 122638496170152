<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row align-items-center">
        <div
            class="rank m-0"
            v-if="!item.index && index < 3"
            v-bind:class="{
                    'rank-1': index === 0,
                    'rank-2': index === 1,
                    'rank-3': index === 2,
                  }"
        ></div>
        <template v-else-if="!item.index && index > 2">{{index + 1}}</template>
        <div
            class="rank m-0"
            v-else-if="item.index && item.index < 3"
            v-bind:class="{
                    'rank-1': item.index === 0,
                    'rank-2': item.index === 1,
                    'rank-3': item.index === 2,
                  }"
        ></div>
        <template v-else-if="item.index && item.index > 2">{{item.index + 1}}</template>
        <div class="table-card__item-info ml-3">
          {{item.name}}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col mb-0">
            <div class="table-card__item-label">
              {{$t('processFBMReport_totallyOrders.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.count_orders}}
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "ProcessFBMReportTableMobile",

    components: {
    },

    props: {
      item: Object,
      index: Number,
    },

    data() {
      return {
        show: false,
      }
    },

    methods: {

    },

  }
</script>

<style lang="scss" scoped>
.rank {
  display: flex;
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  background-position: center center;
  background-repeat: no-repeat;

  &.rank-1 {
    background-image: url("../../../../../../../../assets/img/common/rank-1.svg");
  }

  &.rank-2 {
    background-image: url("../../../../../../../../assets/img/common/rank-2.svg");
  }

  &.rank-3 {
    background-image: url("../../../../../../../../assets/img/common/rank-3.svg");
  }
}
</style>
