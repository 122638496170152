var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-card__item-content",class:{show: _vm.show}},[_c('div',{staticClass:"table-card__item-head"},[_c('div',{staticClass:"table-card__item-head-row align-items-center"},[(!_vm.item.index && _vm.index < 3)?_c('div',{staticClass:"rank m-0",class:{
                  'rank-1': _vm.index === 0,
                  'rank-2': _vm.index === 1,
                  'rank-3': _vm.index === 2,
                }}):(!_vm.item.index && _vm.index > 2)?[_vm._v(_vm._s(_vm.index + 1))]:(_vm.item.index && _vm.item.index < 3)?_c('div',{staticClass:"rank m-0",class:{
                  'rank-1': _vm.item.index === 0,
                  'rank-2': _vm.item.index === 1,
                  'rank-3': _vm.item.index === 2,
                }}):(_vm.item.index && _vm.item.index > 2)?[_vm._v(_vm._s(_vm.item.index + 1))]:_vm._e(),_c('div',{staticClass:"table-card__item-info ml-3"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")])],2)]),_c('transition',{attrs:{"name":"slide"}},[(_vm.show)?_c('div',{staticClass:"table-card__item-body"},[_c('div',{staticClass:"table-card__item-row custom-row"},[_c('div',{staticClass:"table-card__item-col custom-col mb-0"},[_c('div',{staticClass:"table-card__item-label"},[_vm._v(" "+_vm._s(_vm.$t('processFBMReport_totallyOrders.localization_value.value'))+" ")]),_c('div',{staticClass:"table-card__item-info"},[_vm._v(" "+_vm._s(_vm.item.count_orders)+" ")])])])]):_vm._e()]),_c('div',{staticClass:"table-card__item-footer"},[_c('div',{staticClass:"table-card__item-show",on:{"click":function($event){_vm.show = !_vm.show}}},[_vm._v(" "+_vm._s(_vm.$t('common_showMore.localization_value.value'))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }