var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"table-filter-wrap"},[(_vm.results.length && !_vm.$store.getters.getProcessFBMReportLoading)?_c('div',{staticClass:"site-table-wrap small-table",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'processFBMReport_number',
            'processFBMReport_user',
            'processFBMReport_totallyOrders',
            ])}}}),(!_vm.isMobileFunc())?_c('table',{staticClass:"site-table mt-0"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-lg-center"},[_vm._v(_vm._s(_vm.$t('processFBMReport_number.localization_value.value')))]),_c('th',[_vm._v(_vm._s(_vm.$t('processFBMReport_user.localization_value.value')))]),_c('th',{staticClass:"right"},[_vm._v(_vm._s(_vm.$t('processFBMReport_totallyOrders.localization_value.value')))]),_c('th',{attrs:{"width":"100%"}})])]),_c('tbody',_vm._l((_vm.results),function(item,index){return _c('tr',{key:index},[_c('td',[(!item.index && index < 3)?_c('div',{staticClass:"rank",class:{
                    'rank-1': index === 0,
                    'rank-2': index === 1,
                    'rank-3': index === 2,
                  }}):(!item.index && index > 2)?[_vm._v(_vm._s(index + 1))]:(item.index && item.index < 3)?_c('div',{staticClass:"rank",class:{
                    'rank-1': item.index === 0,
                    'rank-2': item.index === 1,
                    'rank-3': item.index === 2,
                  }}):(item.index && item.index > 2)?[_vm._v(_vm._s(item.index + 1))]:_vm._e()],2),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{attrs:{"align":"right"}},[_vm._v(_vm._s(item.count_orders))]),_c('td')])}),0)]):_c('div',{staticClass:"table-card"},[_c('div',{staticClass:"table-card__list pt-1"},_vm._l((_vm.results),function(item,index){return _c('div',{key:index,staticClass:"table-card__item"},[_c('ProcessFBMReportTableMobile',{attrs:{"item":item,"index":index}})],1)}),0)]),_vm._m(0)]):(!_vm.$store.getters.getProcessFBMReportLoading)?_c('NoResult',{attrs:{"title":_vm.$t('common_noResult.localization_value.value'),"text":_vm.$t('common_noResultSeems.localization_value.value')}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-bottom-btn"},[_c('div',{staticClass:"table-bottom-btn__inner"})])
}]

export { render, staticRenderFns }