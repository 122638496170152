<template>
  <ProcessFBMReportTableAdmin
      :results="results"
      :dataRightBlock="dataRightBlock"
      :adminOptions="adminOptions"
      @filter="filter"
  />
</template>

<script>
  import ProcessFBMReportTableAdmin from "./ProcessFBMReportTableAdmin/ProcessFBMReportTableAdmin";
  import {queryFilterMixin} from "../../../../../mixins/routeFilterMixins/queryFilterMixin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "ProcessFBMReportTable",

    components: {
      ProcessFBMReportTableAdmin,
    },

    mixins: [queryFilterMixin, routeFilter],
    
    created() {
      this.$store.dispatch('fetchAdminUsersFromFilter', '').then(response => {
        this.adminOptions = this.getRespData(response)
      })
    },

    data() {
      return {
        results: [],
        resultsForIndex: [],
        adminOptions: [],
        dataRightBlock: {
          filterDate: [
            this.$moment(new Date()).subtract(1, 'months').format("MM/DD/YY"),
            this.$moment(new Date()).format("MM/DD/YY")
          ],
          filterDateStart: this.$moment(new Date()).subtract(1, 'months').format("MM/DD/YY"),
          filterDateEnd: this.$moment(new Date()).format("MM/DD/YY"),
        },

      }
    },

    mounted() {
      this.filter()
    },

    methods: {
      filter(params) {
        let myQuery = this.createQueryBuilderObject()

        let query = this.$route.query
        delete query['updatedAt']
        if (this.dataRightBlock.filterDate[0] !== null && this.dataRightBlock.filterDate[1] !== null) {
          this.$router.push({ path: '', query: {
              'updatedAt': this.$moment(this.dataRightBlock.filterDate[0]).format('YYYY-MM-DD')+ ','
                  + this.$moment(this.dataRightBlock.filterDate[1]).format('YYYY-MM-DD'),
            }})

          myQuery.where('updatedAt', this.$route.query.updatedAt )
        }


        if (params?.adminId) {
          let url = this.generateGetParamsFromQuery(myQuery, 1000, 0)
          this.$store.dispatch('fetchProcessFBMReport', url).then(response => {
            this.resultsForIndex = this.getRespData(response).result

            this.$router.replace({name: '', query: {...this.$route.query, adminId: params.adminId}})

            myQuery.where('adminUserId', this.$route.query.adminId )

            //local url variable
            let url = this.generateGetParamsFromQuery(myQuery, 1000, 0)

            this.$store.dispatch('fetchProcessFBMReport', url).then(response => {
              this.results = this.getRespData(response).result
              this.results = this.results.map(item => {
                //set index value
                item.index = this.resultsForIndex.findIndex(indexItem => {
                  return indexItem.admin_user_id === item.admin_user_id
                })

                return item
              })
            })
          })


        }
        else {
          let url = this.generateGetParamsFromQuery(myQuery, 1000, 0)

          this.$store.dispatch('fetchProcessFBMReport', url).then(response => {
            this.results = this.getRespData(response).result
          })
        }


      },

    }
  }
</script>

<style scoped>

</style>