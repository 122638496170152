<template>
  <div class="fragment">
    <ProcessFBMReportHead/>

    <div class="detail-page reverse">
      <div class="detail-page__left">
        <CardLeftBlock
            class="clear-992 height-search-engine"
            :backgroundImage="'process-report'"
        >
          <template slot="body">
            <div class="process-fbm-report">
              <div class="process-fbm-report__title"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['processFBMReport_title'])"></div>
                {{$t('processFBMReport_title.localization_value.value')}}
              </div>
              <div class="custom-row">
                <div class="custom-col"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['processFBMReport_selectUser'])"></div>
                  <DefaultSelect
                      :options="adminOptions"
                      :optionsLabel="'email'"
                      :otherValue="'adminItem'"
                      @change="(val) => {changeAdminsFilter(val)}"
                      :selected="adminItem"
                      :label="$t('processFBMReport_selectUser.localization_value.value')"
                  />
                </div>
                <div class="custom-col">
                  <DatePickerDefault
                      :value="dataRightBlock.filterDateStart"
                      class="date-picker-time-custom profit-report__dp"
                  >
                    <template slot="body">
                      <date-picker
                          v-model="dataRightBlock.filterDate"
                          ref="datePicker"
                          valueType="format"
                          :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                          :format="'MM/DD/YY'"
                          :placeholder="'mm/dd/yy'"
                          range
                      ></date-picker>
                    </template>
                  </DatePickerDefault>
                </div>
              </div>
              <div class="custom-row">
                <div class="custom-col mb-2"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['processFBMReport_filter'])"></div>
                  <MainButton
                      :value="$t('processFBMReport_filter.localization_value.value')"
                      @click.native="$emit('filter', {adminId: adminId})"
                  />
                </div>
              </div>
            </div>
          </template>
        </CardLeftBlock>
      </div>

      <div class="detail-page__right">
        <ProcessFBMReportTable
            :results="results"
        />
      </div>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import ProcessFBMReportHead from "./ProcessFBMReportHead/ProcessFBMReportHead";
  import ProcessFBMReportTable from "./ProcessFBMReportTable/ProcessFBMReportTable";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import DatePickerDefault from "../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";

  export default {
    name: "ProcessFBMReportTableAdmin",
    components: {
      MainButton,
      CardLeftBlock,
      ProcessFBMReportHead,
      ProcessFBMReportTable,
      DefaultSelect,
      DatePickerDefault,
      DatePicker,
    },

    props: {
      dataRightBlock: Object,
      results: Array,
      adminOptions: Array,
    },

    data(){
      return{
        options: [{}],
        date: '',
        adminItem: null,
        adminId: this.$route.query.adminId ? this.$route.query.adminId : '',
      }
    },

    methods: {
      changeAdminsFilter(value) {
        this.adminItem = value
        this.adminId = value.id
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../scss/mixins/mixins";
  @import "../../../../../../scss/colors";

  .process-fbm-report{
    max-width: 400px;
    width: 100%;

    &__title{
      color: $black;
      font-weight: bold;
      font-size: 32px;
      line-height: 37px;
      margin-bottom: 30px;

      @include for-992{
        font-size: 24px;
        margin-bottom: 20px;
        line-height: normal;
      }
    }
  }
</style>
