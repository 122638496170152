<template>
  <div class="fragment">
    <div class="table-filter-wrap">
      <div class="site-table-wrap small-table"
           v-if="results.length && !$store.getters.getProcessFBMReportLoading"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'processFBMReport_number',
            'processFBMReport_user',
            'processFBMReport_totallyOrders',
            ])"></div>
        <table class="site-table mt-0" v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th class="text-lg-center">{{$t('processFBMReport_number.localization_value.value')}}</th>
            <th>{{$t('processFBMReport_user.localization_value.value')}}</th>
            <th class="right">{{$t('processFBMReport_totallyOrders.localization_value.value')}}</th>
            <th width="100%"></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in results" :key="index">
            <td>
              <div
                  class="rank"
                  v-if="!item.index && index < 3"
                  v-bind:class="{
                    'rank-1': index === 0,
                    'rank-2': index === 1,
                    'rank-3': index === 2,
                  }"
              ></div>
              <template v-else-if="!item.index && index > 2">{{index + 1}}</template>
              <div
                  class="rank"
                  v-else-if="item.index && item.index < 3"
                  v-bind:class="{
                    'rank-1': item.index === 0,
                    'rank-2': item.index === 1,
                    'rank-3': item.index === 2,
                  }"
              ></div>
              <template v-else-if="item.index && item.index > 2">{{item.index + 1}}</template>
            </td>
            <td>{{item.name}}</td>
            <td align="right">{{item.count_orders}}</td>
            <td></td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list pt-1">

            <div class="table-card__item"
                 v-for="(item, index) in results"
                 :key="index"
            >
              <ProcessFBMReportTableMobile
                  :item="item"
                  :index="index"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
<!--            <ShowMore :count="'25'"/>-->
          </div>
        </div>
      </div>


      <NoResult
          v-else-if="!$store.getters.getProcessFBMReportLoading"
          :title="$t('common_noResult.localization_value.value')"
          :text="$t('common_noResultSeems.localization_value.value')"
      />
      <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->


    </div>
  </div>
</template>

<script>
  // import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ProcessFBMReportTableMobile from "./ProcessFBMReportTableMobile/ProcessFBMReportTableMobile";

  export default {
    name: "ProcessFBMReportTable",
    components: {
      ProcessFBMReportTableMobile,
      NoResult
      // ShowMore,
    },

    mixins: [mixinDetictingMobile],

    props: {
      results: Array,
    },

    data(){
      return{
        show1: false,
        show2: false,
        show3: false,
        show4: false,
      }
    }
  }
</script>

<style lang="scss" scoped>

  td:first-child{
    text-align: center;
  }

  .site-table thead th,
  .site-table tbody td{
    padding-right: 40px;
  }

  .rank{
    display: flex;
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    background-position: center center;
    background-repeat: no-repeat;

    &.rank-1{
      background-image: url("../../../../../../../assets/img/common/rank-1.svg");
    }

    &.rank-2{
      background-image: url("../../../../../../../assets/img/common/rank-2.svg");
    }

    &.rank-3{
      background-image: url("../../../../../../../assets/img/common/rank-3.svg");
    }
  }

</style>
